<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Configuración</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="configuraciones" :select="seleccionar" @actions="dataGridActions">
			<div class="col-md-2">
				<button class="btn warning-btn mr-2" @click="obtener_configuracion">Recargar</button>
			</div>
			<div class="col-md-2">
				<button class="btn complementary-btn mr-2" v-if="this.$auth.can('tesoreria','configuracion_crear')" @click="accion=1">Crear</button>
			</div>
			<div class="col-md-2" v-if="this.$auth.can('tesoreria','configuracion_actualizar')">
				<button class="btn secondary-btn mr-2" v-if="this.$auth.can('tesoreria','configuracion_actualizar')" @click="actualziar_config">Actualizar</button>
			</div>
			<div class="col-md-2" v-if="this.$auth.can('tesoreria','configuracion_eliminar')">
				<button class="btn danger-btn mr-2" v-if="this.$auth.can('tesoreria','configuracion_eliminar')" @click="eliminar_config">Eliminar</button>
			</div>
		</DataGrid>

		<Modal v-if="accion!==null" :options="{width: '60vw',close:true}" @close="cancelar_config">
			<div class="title">Configuración</div>
			<div class="body">
				<div v-if="accion==1 || this.$auth.can('tesoreria','configuracion_actualizar_llave')" class="row form-group">
					<label for="llave" class="col-form-label col-sm-2">Llave</label>
					<div class="col-sm-10">
						<input v-model="configuracion.llave" type="text" name="llave" id="llave" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="nombre" class="col-form-label col-sm-2">Nombre</label>
					<div class="col-sm-10">
						<input v-model="configuracion.nombre" type="text" name="nombre" id="nombre" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="valor" class="col-form-label col-sm-2">Valor</label>
					<div class="col-sm-10">
						<input v-model="configuracion.valor" type="text" name="valor" id="valor" class="form-control">
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-md-2 offset-md-8">
						<button class="btn complementary-btn"@click="guardar_config">Guardar</button>
					</div>
					<div class="col-md-2">
						<button class="btn danger-btn" @click="cancelar_config">Cancelar</button>
					</div>
				</div>
			</div>
		</Modal>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid';
	import Modal from '@/components/Modal';

	import tesoreria from '@/apps/tesoreria/api/tesoreria'

	export default {
		components: {
			DataGrid, Modal
		}
		,data: () => ({
			dataGridConfig: {
				name: 'facturas'
				,cols: {
					llave: 'Llave'
					,nombre: 'Nombre'
					,valor: 'Valor'
					,created_at: 'Fecha creación'
					,updated_at: 'Fecha de actualización'
				}
				,filters: {
					cols: {
						id: 'ID'
						,llave: 'Llave'
						,nombre: 'Nombre'
						,valor: 'Valor'
						,created_at: 'Fecha creación'
						,updated_at: 'Fecha de actualización'
						,created_at: 'Fecha de creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					total: function(val) {
						return '$'+val;
					}
					,subtotal: function(val) {
						return '$'+val;
					}
					,proveedor: function(val) {
						return val.nombre;
					}
					,estatus: function(val) {
						let mssg = '';
						switch(val) {
							case 0:
								mssg = '<span style="color:#FA3030;font-weight:bold">'+this.buscar_estatus(0)+'</span>';
								break;
							case 1:
								mssg = '<span style="color:#4545FF;font-weight:bold">'+this.buscar_estatus(1)+'</span>';
								break;
							case 2:
								mssg = '<span style="color:#FF9E44;font-weight:bold">'+this.buscar_estatus(2)+'</span>';
								break;
							case 3:
								mssg = '<span style="color:#4AD627;font-weight:bold">'+this.buscar_estatus(3)+'</span>';
								break;
							case 4:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+this.buscar_estatus(4)+'</span>';
								break;
							case 5:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+this.buscar_estatus(5)+'</span>';
								break;
							case 6:
								mssg = '<span style="color:#5A96E2;font-weight:bold">'+this.buscar_estatus(6)+'</span>';
								break;
						}

						return mssg
					}
					,id: function(val, row, vue) {
						return '<button class="btn principal-btn" name="download_files" value="'+val+'" data-json="'+(btoa(JSON.stringify(row)))+'">Descargar</button>';
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,accion: null
			,configuraciones: []
			,configuracion: {
				llave:null
				,nombre:null
				,valor:null
			}
			,seleccionadas: []
			,seleccionar: false
			,estatus: []
		})
		,mounted: function() {
			
		}
		,methods: {
			obtener_configuracion: function() {
				tesoreria.obtener_configuraciones(this.options)
				.then(res => {
					this.$log.info('res', res);
					this.configuraciones = res.data.data;
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err,'Error');
				})
			}
			,dataGridActions: function(tipo, data) {
				this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.obtener_configuracion();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,cancelar_config: function() {
				this.configuracion = {
					llave:null
					,nombre:null
					,valor:null
				};

				this.accion=null;

				this.seleccionar = false;
			}
			,guardar_config: function() {
				if (!this.$auth.can('tesoreria','configuracion_actualizar_llave'))
					delete this.configuracion.llave;

				if (this.accion == 1 && this.$auth.can('tesoreria','configuracion_crear'))
					tesoreria.crear_configuracion(this.configuracion)
					.then(res => {
						this.$log.info('res', res);
						this.cancelar_config();
						this.obtener_configuracion();
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				else if (this.accion == 2 && this.$auth.can('tesoreria','configuracion_actualizar'))
					tesoreria.actualizar_configuracion(this.configuracion.id, this.configuracion)
					.then(res => {
						this.$log.info('res', res);
						this.cancelar_config();
						this.obtener_configuracion();
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				else
					this.$helper.showAxiosError('No tienes permisos para realizar esta acción', 'Error');
			}
			,actualziar_config: function() {
				if (this.seleccionadas.length > 0) {
					this.configuracion = this.seleccionadas[0];
					this.accion=2;
				}else
					this.$helper.showAxiosError('Tienes que seleccionar una configuración', 'Error');
			}
			,eliminar_config: async function() {
				if (this.$auth.can('tesoreria','configuracion_eliminar'))
					if (this.seleccionadas.length > 0) {
						for(let i=0; i<this.seleccionadas.length; i++) {
							await tesoreria.eliminar_configuracion(this.seleccionadas[i].id)
							.then(res => {
								this.$log.info('res', res);
							})
							.catch(err => {
								this.$log.info('err', err);
							});
						}

						this.cancelar_config();
						this.obtener_configuracion();
					}else
						this.$helper.showAxiosError('Tienes que seleccionar al menos una configuración', 'Error');
				else
					this.$helper.showAxiosError('No tienes permisos para realizar esta acción', 'Error');
			}
			,cargar_estatus: function() {
				tesoreria.cargar_estatus()
				.then(res => {
					this.$log.info('res', res);
					this.estatus = res.data;
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err,'Error');
				})
			}
			,buscar_estatus: function(estatus) {
				let nombre = '';
				for(let i=0; i<this.estatus.length; i++) {
					if (this.estatus[i].valor == estatus) {
						nombre = this.estatus[i].nombre;
						i = this.estatus.length;
					}
				}

				return nombre;
			}
		}
	}
</script>